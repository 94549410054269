<template>
  <b-form v-on:submit.prevent="save">
    <div class="d-flex flex-lg-row flex-column mt-5">
      <div class="flex-grow-1 mr-5">
        <!-- begin: Name    -->
        <b-form-group
          :label="$t('EMPLOYEESGENERAL.MESSAGE.FIRSTNAME')"
          label-cols-sm="4"
          label-cols-lg="3"
        >
          <b-form-input
            v-model="form.name"
            :state="validateState('name')"
          ></b-form-input>
        </b-form-group>
        <!-- end: Name    -->

        <!-- begin: Description    -->
        <b-form-group
          :label="$t('DEPARTMENTFORM.DESCRIPTION')"
          label-cols-sm="4"
          label-cols-lg="3"
        >
          <b-form-textarea
            v-model="form.description"
            :state="validateState('description')"
          ></b-form-textarea>
        </b-form-group>
        <!-- end: Description    -->
      </div>
    </div>
  </b-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
import swal from "sweetalert";
import { mapGetters, mapState } from "vuex";

export default {
  name: "EmployeeGroupEditForm",
  mixins: [validationMixin],
  data() {
    return {
      form: {
        name: "",
        description: "",
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3),
      },
      description: {
        required,
        minLength: minLength(3),
      },
    },
  },
  beforeMount() {
    const group = this.getGroupInformation;
    this.form.name = group.name;
    this.form.description = group.description;
    this.form.id = this.getGroupInformation.id;
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    save() {
      console.log("Employee Group Form submission");
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.$store.dispatch("updateEmployeeGroupForm", this.form).then(() => {
        this.$emit("success", true);
        swal("Success!");
      });
    },
  },
  computed: {
    ...mapState({
      isBusy: (state) =>
        state.CompanyModule.EmployeeGroupStore.EmployeeGroupForm.isBusy,
    }),
    ...mapGetters([
      "getGroupInformation",
      "getHeldEmpGroupSchedule",
      "checkUpdateEmpGroupScheduleEvent",
    ]),
  },
  watch: {
    checkUpdateEmpGroupScheduleEvent(newState, oldState) {
      console.log("Component", newState, oldState);
      this.save();
    },
  },
};
</script>

<style scoped></style>
