<template>
  <!-- begin: GroupMembershipTable Container  -->
  <div>
    <b-table
      table-class="table-head-custom table-vertical-center table-head-bg mt-5"
      borderless
      responsive="sm"
      :busy="getMembershipListBusy"
      :items="getMembershipList"
      :fields="fields"
      show-empty
    >
      <!-- begin: t-head -->
      <template v-slot:head(index)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>
      <!-- end: t-head -->

      <!-- begin: t-row -->
      <!-- begin: t-row -->
      <template v-slot:cell(index)="data">
        <div class="d-flex mt-1 mb-2">
          <b-form-checkbox
            size="lg"
            v-model="data.item.selected"
            class="h6 mr-3"
          ></b-form-checkbox>
        </div>
      </template>

      <template v-slot:cell(employee)="data">
        <div class="d-flex flex-column my-3">
          <span class="font-weight-bolder">
            {{ data.item.first_name }} {{ data.item.last_name }}
          </span>
          <span class="text-muted">
            {{ data.item.unique_code }}
          </span>
        </div>
      </template>

      <!-- end: t-row -->

      <template #table-busy>
        <AppLoader></AppLoader>
      </template>

      <template #empty>
        <AppEmptyList
          text-top="Empty Group"
          text-bottom="Please add employees to this group in order for the list to appear."
        ></AppEmptyList>
      </template>
    </b-table>
  </div>
  <!-- begin: GroupMembershipTable Container -->
</template>

<script>
import { mapGetters } from "vuex";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import AppEmptyList from "@/modules/core/components/empty/AppEmptyList";

export default {
  name: "GroupMembershipTable",
  components: { AppEmptyList, AppLoader },
  data: function () {
    return {
      fields: [
        { key: "index", label: "#" },
        { key: "employee", label: this.$t("EMPLOYEETABLE.FULLNAME") },
        { key: "unique_code", label: "Code" },
      ],
    };
  },
  mounted() {
    this.$store.dispatch("fetchGroupMembers");
  },
  computed: {
    ...mapGetters(["getMembershipListBusy", "getMembershipList"]),
  },
};
</script>

<style scoped></style>
