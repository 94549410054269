<template>
  <b-modal
    :title="name"
    id="group-membership-modal"
    lazy
    :busy="isBusy"
    size="xl"
  >
    <b-tabs pills v-model="tabIndex" nav-class="nav-light-primary">
      <b-tab lazy title="In Group">
        <GroupMembershipTable></GroupMembershipTable>
      </b-tab>
      <b-tab lazy title="Available">
        <GroupMembershipAvailableTable></GroupMembershipAvailableTable>
      </b-tab>
    </b-tabs>

    <!-- begin: Modal Footer   -->
    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="secondary"
        v-on:click="destroy"
        v-if="tabIndex === 0"
        :disabled="isEmptyList"
        v-bind:class="{
          'spinner spinner-light spinner-right': isBusy === true,
        }"
        >Delete</b-button
      >
      <b-button
        variant="primary"
        v-on:click="save"
        v-if="tabIndex === 1"
        :disabled="isEmptyList"
        v-bind:class="{
          'spinner spinner-light spinner-right': isBusy === true,
        }"
        >Save</b-button
      >
      <b-button
        :disabled="isBusy"
        variant="light"
        class="btn-hover-danger"
        @click="cancel"
        >{{ $t("BUTTON.CANCEL") }}</b-button
      >
    </template>
    <!-- end: Modal Footer   -->
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import swal from "sweetalert";
import GroupMembershipTable from "@/modules/company/components/group/membership/GroupMembershipTable";
import GroupMembershipAvailableTable from "@/modules/company/components/group/membership/GroupMembershipAvailableTable";

export default {
  name: "GroupMembershipModal",
  components: { GroupMembershipAvailableTable, GroupMembershipTable },
  data: function () {
    return {
      tabIndex: 0,
      isBusy: false,
    };
  },
  computed: {
    ...mapGetters({
      group: "fetchEmployeeGroup",
      isEmptyList: "getMembershipEmpty",
    }),
    name() {
      return this.group.name ? this.group.name + " Employee Group" : "";
    },
  },
  methods: {
    save() {
      this.isBusy = true;
      this.$store
        .dispatch("saveEmployeeGroupMembers")
        .then(() => swal("Success"))
        .finally(() => (this.isBusy = false));
    },
    destroy() {
      this.isBusy = true;
      this.$store
        .dispatch("deleteEmployeeGroupMembers")
        .then(() => swal("Success"))
        .finally(() => (this.isBusy = false));
    },
  },
};
</script>

<style scoped></style>
