<template>
  <div>
    <b-table
      ref="employee-group-table"
      responsive="sm"
      :fields="fields"
      :items="getEmployeeGroup"
      :busy="isBusy"
      table-class="table-head-custom table-vertical-center mt-10"
    >
      <!-- begin: t-head -->
      <template v-slot:head(index)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>
      <!-- end: t-head -->

      <!-- begin: t-row -->
      <template v-slot:cell(index)="data">
        <span class="font-weight-bolder">{{ data.index + 1 }}</span>
      </template>
      <!-- end: t-row -->

      <!--  begin:: actions    -->
      <template v-slot:cell(actions)="data">
        <div class="d-flex flex-row justify-content-left">
          <!-- begin::: edit-employee button -->
          <button
            class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
            v-on:click="showEditForm(data)"
          >
            <span class="svg-icon svg-icon-primary menu-icon">
              <inline-svg src="/media/svg/icons/Communication/Write.svg" />
            </span>
          </button>
          <!-- end::: edit-employee button -->

          <!-- begin::: show-employee group btn        -->
          <button
            class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
            v-on:click="showEmployeeGroup(data)"
          >
            <span class="svg-icon svg-icon-primary menu-icon">
              <inline-svg src="/media/svg/icons/Communication/Group.svg" />
            </span>
          </button>
          <!-- end::: show-employee group btn        -->

          <button
            class="btn btn-icon btn-light btn-hover-danger btn-sm mr-3"
            v-on:click="deleteEmployeeGroup(data)"
          >
            <span class="svg-icon svg-icon-gray menu-icon">
              <inline-svg src="/media/svg/icons/General/Trash.svg" />
            </span>
          </button>
          <!-- end::: delete-employee button -->
        </div>
      </template>
      <!--  end:: actions    -->
      <template #table-busy>
        <AppLoader></AppLoader>
      </template>
    </b-table>
    <GroupMembershipModal></GroupMembershipModal>
    <EmployeeGroupModal></EmployeeGroupModal>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import GroupMembershipModal from "@/modules/company/components/group/membership/GroupMembershipModal";
import EmployeeGroupModal from "@/modules/company/components/group/EmployeeGroupModal";
import swal from "sweetalert";

export default {
  name: "EmployeeGroupPageTable",
  components: { EmployeeGroupModal, GroupMembershipModal, AppLoader },
  props: ["reload"],
  data() {
    return {
      fields: [
        // A virtual column that doesn't exist in items
        { key: "index", label: "#" },
        // A virtual column made up from two fields
        { key: "name", label: this.$t("EMPLOYEETABLE.FULLNAME") },
        // Email
        { key: "description", label: this.$t("DEPARTMENTFORM.DESCRIPTION") },
        { key: "actions", label: this.$t("EMPLOYEETABLE.ACTIONS") },
      ],
      testItems: [
        {
          name: "Haha",
          description: "Haha",
        },
      ],
    };
  },
  mounted() {
    return this.$store.dispatch("getEmployeeGroup").then((response) => {
      console.log("Received", response);
      // this.items = response.data.data;
      return response;
    });
  },
  methods: {
    getGroups() {
      return this.$store.dispatch("getEmployeeGroup").then((response) => {
        console.log("Received", response);
        // this.items = response.data.data;
        return response;
      });
    },
    showEmployeeGroup(data) {
      console.log(data);
      const group = {
        id: data.item.id,
        name: data.item.name,
        description: data.item.description,
      };
      this.$store.dispatch("specifyGroup", group);
      this.$bvModal.show("group-membership-modal");
    },
    showEditForm(data) {
      console.log(data);
      const group = {
        id: data.item.id,
        name: data.item.name,
        description: data.item.description,
      };
      this.$store.dispatch("specifyEmpGroupEdit", group);
      this.$bvModal.show("employee-group-edit-modal");
    },
    deleteEmployeeGroup(data) {
      console.log(data);
      const groupName = data.item.name;
      swal({
        title: this.$t("ALERT.SURE"),
        text: groupName + " will be deleted",
        icon: "warning",
        showCancelButton: true,
        dangerMode: true,
        buttons: [this.$t("BUTTON.CANCEL"), this.$t("BUTTON.OKAY")],
        closeOnConfirm: false,
        closeOnCancel: false,
      }).then((willDelete) => {
        if (willDelete) {
          swal(this.$t("ALERT.CONFIRM"), {
            icon: "success",
            text: groupName + " successfully deleted",
          });
        }
      });
    },
  },
  computed: {
    ...mapState({
      isBusy: (state) =>
        state.CompanyModule.EmployeeGroupStore.EmployeeGroupList.isBusy,
    }),
    ...mapGetters(["getEmployeeGroup"]),
  },
  watch: {
    reload() {
      console.log("Reload Table!");
      this.$store.dispatch("getEmployeeGroup");
    },
  },
};
</script>

<style scoped></style>
