<template>
  <div class="d-flex flex-column">
    <b-table
      id="group-membership-available-table"
      ref="group-membership-available-table"
      table-class="table-head-custom table-vertical-center table-head-bg mt-5"
      responsive="sm"
      borderless
      :items="getMembershipList"
      :fields="fields"
      :busy="getMembershipListBusy"
      show-empty
    >
      <!-- begin: t-head -->
      <template v-slot:head(index)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>
      <!-- end: t-head -->

      <!-- begin: t-row -->
      <template v-slot:cell(index)="data">
        <div class="d-flex mt-1 mb-2">
          <b-form-checkbox
            size="lg"
            v-model="data.item.selected"
            class="h6 mr-3"
          ></b-form-checkbox>
        </div>
      </template>

      <template v-slot:cell(employee)="data">
        <div class="d-flex flex-column my-3">
          <span class="font-weight-bolder">
            {{ data.item.first_name }} {{ data.item.last_name }}
          </span>
          <span class="text-muted">
            {{ data.item.unique_code }}
          </span>
        </div>
      </template>

      <!-- end: t-row -->

      <template #table-busy>
        <AppLoader></AppLoader>
      </template>

      <template #empty>
        <AppEmptyList
          text-top="No Available Employees"
          text-bottom="No employees are available to be added to this group. Please check whether there are no existing registered employees."
        ></AppEmptyList>
      </template>
    </b-table>

    <b-pagination
      pills
      class="align-self-end"
      v-model="getMembershipPagination.current_page"
      :total-rows="getMembershipPagination.total"
      :per-page="getMembershipPagination.per_page"
      @change="change"
    >
    </b-pagination>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppLoader from "@/modules/core/components/loaders/AppLoader";

export default {
  name: "GroupMembershipAvailableTable",
  components: { AppLoader },
  data: function () {
    return {
      page: 1,
      fields: [
        { key: "index", label: "#" },
        { key: "employee", label: this.$t("EMPLOYEETABLE.FULLNAME") },
        { key: "unique_code", label: "Unique Code" },
      ],
    };
  },
  created() {
    // https://dev.to/viniciuskneves/watch-for-vuex-state-changes-2mgj
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === "setGroupMembershipSuccess") {
        console.log("Observing setGroupMembershipSuccess");
        this.$store.dispatch("fetchAvailableGroupMembers");
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  mounted() {
    this.$store.dispatch("fetchAvailableGroupMembers");
  },
  methods: {
    change(page) {
      console.log("Current Page", this.meta.current_page);
      this.meta.current_page = page;
      this.$store.dispatch("fetchAvailableGroupMembers");
    },
  },
  computed: {
    ...mapGetters([
      "getMembershipListBusy",
      "getMembershipList",
      "getMembershipPagination",
    ]),
  },
};
</script>

<style scoped></style>
