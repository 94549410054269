<template>
  <div class="p-5">
    <b-form>
      <!-- begin: Monday -->
      <p class="text-muted text-uppercase">Monday</p>
      <div class="d-flex">
        <!-- begin: Time-In    -->
        <b-form-group label="Type" class="flex-fill">
          <b-form-select
            v-model="form.monday.day_type"
            :options="dayTypes"
            :state="validateState('monday')"
          >
          </b-form-select>
        </b-form-group>

        <b-form-group class="ml-2 flex-fill" label="Start">
          <b-form-timepicker
            v-model="form.monday.time_in"
            :state="validateState('monday')"
          ></b-form-timepicker>
        </b-form-group>

        <b-form-group class="ml-2 flex-fill" label="End">
          <b-form-timepicker
            class=""
            v-model="form.monday.time_out"
            :state="validateState('monday')"
          ></b-form-timepicker>
        </b-form-group>
        <!-- end: Time-In    -->
      </div>
      <!-- end: Monday -->

      <!-- begin: Tuesday -->
      <p class="text-muted text-uppercase">Tuesday</p>
      <div class="d-flex">
        <!-- begin: Time-In    -->
        <b-form-group label="Type" class="flex-fill">
          <b-form-select
            v-model="form.tuesday.day_type"
            :options="dayTypes"
            :state="validateState('tuesday')"
          >
          </b-form-select>
        </b-form-group>

        <b-form-group class="ml-2 flex-fill" label="Start">
          <b-form-timepicker
            v-model="form.tuesday.time_in"
            :state="validateState('tuesday')"
          ></b-form-timepicker>
        </b-form-group>

        <b-form-group class="ml-2 flex-fill" label="End">
          <b-form-timepicker
            v-model="form.tuesday.time_out"
            :state="validateState('tuesday')"
          ></b-form-timepicker>
        </b-form-group>
        <!-- end: Time-In    -->
      </div>
      <!-- end: Tuesday -->

      <!-- begin: Wednesday -->
      <p class="text-muted text-uppercase">Wednesday</p>
      <div class="d-flex">
        <!-- begin: Time-In    -->
        <b-form-group label="Type" class="flex-fill">
          <b-form-select
            v-model="form.wednesday.day_type"
            :options="dayTypes"
            :state="validateState('wednesday')"
          >
          </b-form-select>
        </b-form-group>

        <b-form-group class="ml-2 flex-fill" label="Start">
          <b-form-timepicker
            v-model="form.wednesday.time_in"
            :state="validateState('wednesday')"
          ></b-form-timepicker>
        </b-form-group>

        <b-form-group class="ml-2 flex-fill" label="End">
          <b-form-timepicker
            v-model="form.wednesday.time_out"
            :state="validateState('wednesday')"
          ></b-form-timepicker>
        </b-form-group>
        <!-- end: Time-In    -->
      </div>
      <!-- end: Wednesday -->

      <!-- begin: Thursday -->
      <p class="text-muted text-uppercase">Thursday</p>
      <div class="d-flex">
        <!-- begin: Time-In    -->
        <b-form-group label="Type" class="flex-fill">
          <b-form-select
            v-model="form.thursday.day_type"
            :options="dayTypes"
            :state="validateState('thursday')"
          >
          </b-form-select>
        </b-form-group>

        <b-form-group class="ml-2 flex-fill" label="Start">
          <b-form-timepicker
            v-model="form.thursday.time_in"
            :state="validateState('thursday')"
          ></b-form-timepicker>
        </b-form-group>

        <b-form-group class="ml-2 flex-fill" label="End">
          <b-form-timepicker
            v-model="form.thursday.time_out"
            :state="validateState('thursday')"
          ></b-form-timepicker>
        </b-form-group>
        <!-- end: Time-In    -->
      </div>
      <!-- end: Thursday -->

      <!-- begin: Friday -->
      <p class="text-muted text-uppercase">Friday</p>
      <div class="d-flex">
        <!-- begin: Time-In    -->
        <b-form-group label="Type" class="flex-fill">
          <b-form-select
            v-model="form.friday.day_type"
            :options="dayTypes"
            :state="validateState('friday')"
          >
          </b-form-select>
        </b-form-group>

        <b-form-group class="ml-2 flex-fill" label="Start">
          <b-form-timepicker
            v-model="form.friday.time_in"
            :state="validateState('friday')"
          ></b-form-timepicker>
        </b-form-group>

        <b-form-group class="ml-2 flex-fill" label="End">
          <b-form-timepicker
            v-model="form.friday.time_out"
            :state="validateState('friday')"
          ></b-form-timepicker>
        </b-form-group>
        <!-- end: Time-In    -->
      </div>
      <!-- end: Friday -->

      <!-- begin: Saturday -->
      <p class="text-muted text-uppercase">Saturday</p>
      <div class="d-flex">
        <!-- begin: Time-In    -->
        <b-form-group label="Type" class="flex-fill">
          <b-form-select
            v-model="form.saturday.day_type"
            :options="dayTypes"
            :state="validateState('saturday')"
          >
          </b-form-select>
        </b-form-group>

        <b-form-group class="ml-2 flex-fill" label="Start">
          <b-form-timepicker
            v-model="form.saturday.time_in"
            :state="validateState('saturday')"
          ></b-form-timepicker>
        </b-form-group>

        <b-form-group class="ml-2 flex-fill" label="End">
          <b-form-timepicker
            v-model="form.saturday.time_out"
            :state="validateState('saturday')"
          ></b-form-timepicker>
        </b-form-group>
        <!-- end: Time-In    -->
      </div>
      <!-- end: Saturday -->

      <!-- begin: Sunday -->
      <p class="text-muted text-uppercase">Sunday</p>
      <div class="d-flex">
        <!-- begin: Time-In    -->
        <b-form-group label="Type" class="flex-fill">
          <b-form-select
            v-model="form.sunday.day_type"
            :options="dayTypes"
            :state="validateState('sunday')"
          >
          </b-form-select>
        </b-form-group>

        <b-form-group class="ml-2 flex-fill" label="Start">
          <b-form-timepicker
            v-model="form.sunday.time_in"
            :state="validateState('sunday')"
          ></b-form-timepicker>
        </b-form-group>

        <b-form-group class="ml-2 flex-fill" label="End">
          <b-form-timepicker
            v-model="form.sunday.time_out"
            :state="validateState('sunday')"
          ></b-form-timepicker>
        </b-form-group>
        <!-- end: Time-In    -->
      </div>
      <!-- end: Sunday -->
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import swal from "sweetalert";
import { mapGetters } from "vuex";

export default {
  name: "EmployeeGroupSchedule",
  mixins: [validationMixin],
  data() {
    return {
      form: {
        monday: {
          day_type: null,
          time_in: "",
          time_out: "",
        },
        tuesday: {
          day_type: null,
          time_in: "",
          time_out: "",
        },
        wednesday: {
          day_type: null,
          time_in: "",
          time_out: "",
        },
        thursday: {
          day_type: null,
          time_in: "",
          time_out: "",
        },
        friday: {
          day_type: null,
          time_in: "",
          time_out: "",
        },
        saturday: {
          day_type: null,
          time_in: "",
          time_out: "",
        },
        sunday: {
          day_type: null,
          time_in: "",
          time_out: "",
        },
        id: null,
      },
      dayTypes: [
        { value: null, text: "Please Select Day Type" },
        { value: 1, text: "Full Day" },
        { value: 3, text: "Off Day" },
      ],
    };
  },
  validations: {
    form: {
      monday: {
        day_type: { required },
        time_in: {
          required: requiredIf(function () {
            return this.form.monday.day_type === 1 ?? true;
          }),
        },
        time_out: {
          required: requiredIf(function () {
            return this.form.monday.day_type === 1 ?? true;
          }),
        },
      },
      tuesday: {
        day_type: {},
        time_in: {},
        time_out: {},
      },
      wednesday: {
        day_type: {},
        time_in: {},
        time_out: {},
      },
      thursday: {
        day_type: {},
        time_in: {},
        time_out: {},
      },
      friday: {
        day_type: {},
        time_in: {},
        time_out: {},
      },
      saturday: {
        day_type: {},
        time_in: {},
        time_out: {},
      },
      sunday: {
        day_type: {},
        time_in: {},
        time_out: {},
      },
    },
  },
  mounted() {
    this.form.id = this.getGroupInformation.id;
    this.$store.dispatch("fetchEmployeeGroupSchedule");
  },
  methods: {
    validateState(name) {
      console.log(name);
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    save() {
      console.log("Employee Group Form submission", this.$v.form);
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.$store.dispatch("saveEmpGroupSchedule", this.form).then(() => {
        this.$emit("success", true);
        swal("Success!");
      });
    },
  },
  computed: {
    ...mapGetters([
      "checkEmpGroupScheduleEvent",
      "getGroupInformation",
      "getEmployeeGroupSchedule",
      "getEmployeeGroupScheduleForm",
    ]),
  },
  watch: {
    checkEmpGroupScheduleEvent(newState, oldState) {
      console.log(newState, oldState);
      this.save();
    },
    getEmployeeGroupSchedule(newSchedule) {
      console.log("Scheduled changed", newSchedule);
    },
    getEmployeeGroupScheduleForm(form) {
      console.log("Form changed", form);
      console.log("Existing form", this.form);

      if (form != null) {
        this.form = form;
      }
    },
  },
};
</script>

<style scoped></style>
