<template>
  <b-modal
    footer-bg-variant="light"
    :title="groupName"
    id="employee-group-edit-modal"
    :size="size"
  >
    <b-tabs
      pills
      nav-class="nav-light-primary"
      v-model="tab"
      @activate-tab="changeModalSize"
    >
      <b-tab title="General" lazy>
        <EmployeeGroupEditForm class="m-2 mt-3 p-2"></EmployeeGroupEditForm>
      </b-tab>
      <b-tab title="Schedule" lazy>
        <EmployeeGroupSchedule class="m-2 mt-3 p-2"></EmployeeGroupSchedule>
      </b-tab>
    </b-tabs>

    <template #modal-footer="{ ok, cancel }">
      <b-button variant="light" class="btn-hover-danger" @click="cancel"
        >{{ $t("BUTTON.CANCEL") }}
      </b-button>

      <b-button
        variant="primary"
        v-if="tab === 0"
        v-bind:class="{
          'spinner spinner-light spinner-right': getHeldEmpEditGroup,
        }"
        v-on:click="updateGroup"
      >
        Update
      </b-button>

      <b-button
        variant="primary"
        v-if="tab === 1"
        v-bind:class="{
          'spinner spinner-light spinner-right': getHeldEmpGroupSchedule,
        }"
        v-on:click="saveSchedule"
        >Save
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import EmployeeGroupEditForm from "@/modules/company/components/group/EmployeeGroupEditForm";
import EmployeeGroupSchedule from "@/modules/company/components/group/schedule/EmployeeGroupSchedule";
import { mapGetters } from "vuex";

export default {
  name: "EmployeeGroupModal",
  components: { EmployeeGroupSchedule, EmployeeGroupEditForm },
  data: function () {
    return {
      size: "md",
      isBusy: false,
      tab: 0,
    };
  },
  methods: {
    saveSchedule() {
      console.log("Emitting schedule save event..");
      this.$store.dispatch("engageEmpGroupFormSubmission");
    },
    changeModalSize(newTabIndex) {
      console.log(newTabIndex);
      switch (newTabIndex) {
        case 0:
          this.size = "md";
          break;
        case 1:
          this.size = "xl";
          break;
        default:
          break;
      }
    },
    updateGroup() {
      console.log("Emitting emp group update event..");
      this.$store.dispatch("engageUpdateEmployeeGroupForm");
    },
  },
  computed: {
    groupName() {
      return this.$store.getters.getGroupInformation.name + " Group";
    },
    ...mapGetters([
      "getGroupInformation",
      "getHeldEmpGroupSchedule",
      "getHeldEmpEditGroup",
    ]),
  },
};
</script>

<style scoped></style>
